const requestURL = '/accounts/sign_in/verify_otp_login_by_email'

document.addEventListener('DOMContentLoaded', () => {
    let form = document.querySelector('#new_account')
    if (!form) return false
    let formCode = document.querySelector('#otp-code')
    if (!formCode) return false
    let submitButton = form.querySelector('button[type="submit"]')

    form.addEventListener('submit', async (e) => {
        if (!formCode.classList.contains('d-none')) return false
        e.preventDefault();
        const formData = new FormData();
        formData.append('email', document.querySelector('#account_email').value);
        const result = await fetch(requestURL, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': getCSRFToken()
            },
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .catch((error) => {
                // TODO handle error with sentry
                console.log('Error on recive verife OTP:', error)
            });
        if (result) {
            formCode.classList.remove('d-none')
            submitButton.disabled = false
        } else {
            submitButton.disabled = false
            formCode.remove()
            form.submit()
        }
    })
})

const getCSRFToken = () => {
    var CSRFSelector = document.querySelector('meta[name="csrf-token"]')
    if (CSRFSelector) {
        return CSRFSelector.getAttribute('content')
    } else {
        return null
    }
}
